<template>
  <header class="section-header">
    <h2 class="section-header__title">
      <slot name="title" />
    </h2>
  </header>
</template>

<script>
export default {
  props: {
  }
};
</script>

<style lang="scss">
.section-header {
  &__title {
    color: $light-black;
    @include setText(40,700,50);
    position: relative;
    margin-bottom: 50px;
    &:before {
      content: '';
      @include pointTick();
      position: absolute;
      top: -36px;
      left: -16px;
    }
  }
}

</style>
