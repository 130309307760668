import { onMounted, ref, watch } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs'

export default function useNotice () {
  const router = useRouter()
  const route = useRoute()

  const noticeData = ref({})

  const fetchOneData = async (noticeId) => {
    const { data: { data } } = await axios.get(`${process.env.VUE_APP_API_HOST}/api/common/notice/${noticeId}`)
    noticeData.value = data
  }

  const onToList = () => {
    //router.go(-1)
    router.push('/notice/list')
  }

  const onToNext = () => {
    router.push(`/notice/detail/${noticeData.value.nextId}`)
  }

  const onToPrev = () => {
    router.push(`/notice/detail/${noticeData.value.previousId}`)
  }

  const convertYmd = (date) => {
    if (!date) {
      return ''
    }
    return dayjs(date).format('YYYY-MM-DD')
  }

  const getTypeStr = (type) => {
    return type === '공지' ? '공지사항'
      : type === '이벤트' ? '이벤트'
        : ''
  }

  onMounted(() => {
    fetchOneData(route.params.id)
  })

  watch(() => route.params.id, (id) => {
    console.log('watchID', id)
    if (id) {
      fetchOneData(id)
    }
  })

  return {
    noticeData,
    fetchOneData,
    onToList,
    onToNext,
    onToPrev,
    convertYmd,
    getTypeStr
  }
}
