<template>
  <article class="notice">
    <pc-notice-detail v-if="!isMobileOnly"/>
    <mobile-notice-detail v-else/>
  </article>
</template>

<script>
import { defineComponent } from 'vue'
import { isMobileOnly } from 'mobile-device-detect'
import PcNoticeDetail from '../../components/pc/notice/NoticeDetail'
import MobileNoticeDetail from '../../components/mobile/notice/NoticeDetail'
export default defineComponent({
  name: 'NoticeDetail',
  components: {
    PcNoticeDetail,
    MobileNoticeDetail
  },
  setup() {

    return {
      isMobileOnly
    }
  }
})
</script>

<style scoped>

</style>
