<template>
  <div v-if="noticeData.id" class="layout small">
    <section-title>
      <template v-slot:title>
        새소식
      </template>
    </section-title>
    <section class="notice-detail">
      <header class="notice-detail-header">
        <mark class="notice-detail__title-mark">
          {{ getTypeStr(noticeData.type) }}
        </mark>
        <h3 class="notice-detail__title">
          {{ noticeData.title }}
        </h3>
        <p class="notice-detail__date">
          {{ convertYmd(noticeData.createdAt) }}
        </p>
      </header>
      <div
        class="notice-detail-content"
        v-html="noticeData.memo"
      ></div>
      <div class="notice-detail-button-area">
        <button
          class="notice-detail__button list"
          @click="onToList"
        >
          목록보기
        </button>
        <button
          v-if="noticeData.previousId"
          class="notice-detail__button"
          @click="onToPrev"
        >
          이전글
        </button>
        <button
          v-if="noticeData.nextId"
          class="notice-detail__button"
          @click="onToNext"
        >
          다음글
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import SectionTitle from '../../layout/SectionTitle'
import useNotice from '@/mixins/useNotice'

export default {
  name: 'NoticeDetail',
  components: {
    SectionTitle
  },
  setup () {
    const { noticeData, onToList, onToNext, onToPrev, convertYmd, getTypeStr } = useNotice()
    return {
      noticeData,
      onToList,
      onToNext,
      onToPrev,
      convertYmd,
      getTypeStr
    }
  }
}
</script>

<style scoped lang="scss">
.notice-detail {
  &-header {
    border-bottom: 2px solid $black2;
    padding-bottom: 20px;
  }
  &-content {
    padding: 24px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: $black2;
    border-bottom: 1px solid $vary-light-gray3;
  }
  &__date {
    color: $grey2;
    font-size: 14px;
    margin-top: 20px;
  }
  &__title {
    font-size: 24px;
    line-height: 35px;
    letter-spacing: -0.05em;
    font-weight: 500;
    &-mark {
      font-weight: 500;
      color: $point2;
      display: block;
      font-size: 24px;
      line-height: 35px;
      letter-spacing: -0.05em;
    }
  }
  &-button-area {
    text-align: center;
    position: relative;
  }
  &__button {
    margin-top: 20px;
    width: 119px;
    height: 48px;
    background-color: #FFFFFF;
    border: 1px solid #F1F3F5;
    box-sizing: border-box;
    border-radius: 6px;
    color: $black;
    text-align: center;
    font-size: 14px;
    &:last-child {
      margin-left: 8px;
    }
    &.list {
      width: 132px;
      background-image: url("../../../assets/images/icons/list.png");
      background-repeat: no-repeat;
      background-position: 24px 50%;
      background-size: 24px;
      padding-left: 56px;
      text-align: left;
      position: absolute;
      left: 0;
    }
    &:hover {
      background-color: $vary-light-gray2;
    }
  }
}
</style>
